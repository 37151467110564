import { useTranslation } from '@getpopsure/i18n-react';
import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { PaymentScreenV2View } from 'features/paymentScreenV2/containers/PaymentScreenV2/PaymentScreen';
import {
  PolicyDetailsConfiguration,
  PriceBreakdownConfiguration,
  processPolicyDetailsData,
  processPolicyDetailsQuestionValue,
  processPriceBreakdown,
} from 'SignupQuestionnaire/components/GenericCheckoutV2/utils';

import { getCheckoutInfo } from '../Checkout/data';

export const GeneralPreviewGenericCheckoutV2 = <
  Questionnaire extends QuestionnaireQuestions
>(
  props: QuestionnaireFormProps<Questionnaire> & {
    genericQuestionnaireKey: string;
    hasCheckoutDocuments?: boolean;
    changeStartDatePath?: string;
    redirectAddDependent?: keyof Questionnaire | boolean;
    policyDetailsConfiguration?: PolicyDetailsConfiguration;
    priceBreakdownConfiguration?: PriceBreakdownConfiguration;
    mainPolicyId?: string;
    productName: string;
    planQuestionId?: string;
  }
) => {
  const { t } = useTranslation();

  const {
    questionnaireAnswers,
    policyDetailsConfiguration,
    productName,
    planQuestionId,
    priceBreakdownConfiguration,
    hasCheckoutDocuments,
  } = props;

  const planName = planQuestionId
    ? processPolicyDetailsQuestionValue(
        questionnaireAnswers[planQuestionId] ??
          Object(questionnaireAnswers.quote)[planQuestionId]
      )
    : undefined;

  const checkoutInfo = getCheckoutInfo(questionnaireAnswers ?? {});

  const policyDetails = policyDetailsConfiguration
    ? processPolicyDetailsData(
        questionnaireAnswers,
        policyDetailsConfiguration,
        productName,
        planName
      )
    : undefined;

  const priceBreakdown = priceBreakdownConfiguration
    ? processPriceBreakdown({
        questionnaireAnswers,
        checkout: checkoutInfo,
        t,
        priceBreakdownConfiguration,
        productName,
        planName,
      })
    : undefined;

  if (!policyDetails) {
    throw new Error('Missing policy details configuration for checkout');
  }

  if (!priceBreakdown) {
    throw new Error('Missing price breakdown configuration for checkout');
  }

  return (
    <PaymentScreenV2View
      policyDetails={policyDetails}
      referralCodeLoading={false}
      onSubmitReferralCode={() => {}}
      onRemoveReferralCode={() => {}}
      documents={[]}
      priceBreakdown={priceBreakdown}
      checkboxValues={[]}
      setCheckboxValues={() => {}}
      isSubmitButtonEnabled={false}
      checkoutInfo={checkoutInfo}
      purchasePolicy={() => {}}
      updatePaymentMethods={() => {}}
      hasCheckoutDocuments={hasCheckoutDocuments ?? false}
      {...props}
    />
  );
};
