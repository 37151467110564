import { Region } from '@getpopsure/public-models';
import {
  PageNotFound,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import LoadingSpinner from 'components/loadingSpinner';
import { QuotePage } from 'components/QuotePage';
import routes from 'constants/routes';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

import { useLoadQuestionnaire } from '../functions';
import { GeneralGenericCheckout } from './components/GenericCheckout/GeneralGenericCheckout';
import { GeneralGenericCheckoutV2 } from './components/GenericCheckoutV2/GeneralGenericCheckout';
import { RegionManager } from './components/RegionManager';
import { GeneralReview } from './components/Review/Review';
import { SignupProcessing } from './components/SignupProcessing/SignupProcessing';
import { SignupQuestionnaire } from './components/SignupQuestionnaire';

const basePath = routes.policies.general.path;
const noRegionPath = routes.policies.general.noRegion.path;
const questionnairePath = routes.policies.general.questionnaire.path;

export const SignupPage = () => {
  const { regionId, verticalId } = useParams<{
    regionId: Region;
    verticalId: string;
  }>();
  const { t } = useSafeTranslation();
  const [answers, setAnswers] = useState<QuestionnaireQuestions>({});
  const json = useLoadQuestionnaire(verticalId);

  if (json === undefined) {
    return <LoadingSpinner />;
  }

  if (!json) {
    return <PageNotFound />;
  }

  const data = json(t, { answers });

  if (!data || (!isStagingOrDev && !data.meta.published)) {
    return <PageNotFound />;
  }

  return (
    <RegionManager
      noRegionPath={noRegionPath}
      questionnairePath={questionnairePath}
      questionnaireMeta={data.meta}
    >
      <SignupQuestionnaire
        basePath={basePath}
        questionnairePath={questionnairePath}
        components={{
          REVIEW: GeneralReview,
          QUOTE: QuotePage,
          GENERIC_CHECKOUT: GeneralGenericCheckout,
          GENERIC_CHECKOUT_V2: GeneralGenericCheckoutV2,
          SIGNUP_PROCESSING: SignupProcessing,
        }}
        data={data}
        regionId={regionId}
        verticalId={verticalId}
        onQuestionnaireAnswersChanged={setAnswers}
      />
    </RegionManager>
  );
};
