import { Region } from '@getpopsure/public-models';
import {
  PageNotFound,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import LoadingSpinner from 'components/loadingSpinner';
import { QuotePage } from 'components/QuotePage';
import routes from 'constants/routes';
import { useLoadPolicyDetail } from 'features/expat/hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

import { useLoadQuestionnaire } from '../functions';
import { GeneralGenericCheckout } from './components/GenericCheckout/GeneralGenericCheckout';
import { GeneralGenericCheckoutV2 } from './components/GenericCheckoutV2/GeneralGenericCheckout';
import { RegionManager } from './components/RegionManager';
import { GeneralReview } from './components/Review/Review';
import { SignupQuestionnaire } from './components/SignupQuestionnaire';

export const SignupPage = () => {
  const { mainPolicyId, regionId, verticalId } = useParams<{
    mainPolicyId: string;
    regionId: Region;
    verticalId: string;
  }>();

  const { t } = useSafeTranslation();
  const [answers, setAnswers] = useState<QuestionnaireQuestions>({});

  const json = useLoadQuestionnaire(verticalId, true);

  const { loading: policyLoading, policy } = useLoadPolicyDetail(mainPolicyId);

  if (json === undefined || policyLoading) {
    return <LoadingSpinner />;
  }

  if (!json) {
    return <PageNotFound />;
  }

  const data = json(t, { answers, mainPolicy: policy });

  if (!data || (!isStagingOrDev && !data.meta.published)) {
    return <PageNotFound />;
  }

  const baseQuestionnairePath =
    routes.policies.general.dependents.questionnaire.path.replace(
      ':mainPolicyId',
      mainPolicyId
    );
  const basePath = routes.policies.general.dependents.path.replace(
    ':mainPolicyId',
    mainPolicyId
  );
  const noRegionPath = routes.policies.general.noRegion.dependents.path.replace(
    ':mainPolicyId',
    mainPolicyId
  );

  return (
    <RegionManager
      noRegionPath={noRegionPath}
      questionnairePath={baseQuestionnairePath}
      questionnaireMeta={data.meta}
    >
      <SignupQuestionnaire
        basePath={basePath}
        questionnairePath={baseQuestionnairePath}
        components={{
          REVIEW: GeneralReview,
          QUOTE: QuotePage,
          GENERIC_CHECKOUT: GeneralGenericCheckout,
          GENERIC_CHECKOUT_V2: GeneralGenericCheckoutV2,
        }}
        data={data}
        regionId={regionId}
        verticalId={verticalId}
        mainPolicy={policy}
        onQuestionnaireAnswersChanged={setAnswers}
      />
    </RegionManager>
  );
};
