import { getRoutes, stateManagerHelper } from '@getpopsure/qnr-framework';
import Session from '@getpopsure/session';
import {
  flushGenericQuestionnaire,
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import { useGetEmailFromUrl } from 'hooks/useGetEmailFromUrl';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { AppState } from 'reducers';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { useSafeTranslation } from '../../shared/i18n';
import { SignInQuestions } from './models';
import { getTranslatedQuestionnaire, SignInComponents } from './questionnaire';

export const SignIn = () => {
  const dispatch = useDispatch();
  const { location } = useHistory<{
    from?: { pathname?: string; search?: string };
  }>();
  const { authenticate, referrer, emailParam, referrerParam } =
    useGetEmailFromUrl();
  const [ignoreRedirect, setIgnoreRedirect] = useState(false);
  const redirectPath = useQueryParamValue('redirect');
  const questionnaireAnswers = useSelector(
    (state: AppState) => state.genericQuestionnaire.userCreation || {}
  );
  const { t } = useSafeTranslation();

  const resetRedirectPath = location.state?.from?.pathname ?? '';
  const resetRedirectPathParam = location.state?.from?.search ?? '';
  const questions = getTranslatedQuestionnaire(Session.isAuthenticated, t);

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, routes.account.signIn.path),
    rules: [],
  };

  const onAnswer = <QuestionId extends keyof SignInQuestions>(
    questionId: QuestionId,
    answer: SignInQuestions[QuestionId]
  ) => {
    dispatch(
      storeGenericQuestionnaireAnswer('userCreation', { [questionId]: answer })
    );

    const answersToRemove = stateManagerHelper(
      {},
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeGenericQuestionnaireAnswer('userCreation', answersToRemove));
  };

  const flushAnswers = useCallback(() => {
    dispatch(flushGenericQuestionnaire('userCreation'));
  }, [dispatch]);

  useEffect(() => {
    // Since this is a sign in/sign up flow,
    // we want to always flush the answers on questionnaire load
    flushAnswers();

    return flushAnswers;
  }, [flushAnswers]);

  useEffect(() => {
    const formattedRedirectPath =
      redirectPath !== null ? redirectPath : routes.me.policies.path;

    if (ignoreRedirect) {
      return;
    }

    dispatch(
      storeGenericQuestionnaireAnswer('userCreation', {
        redirectPath: resetRedirectPath
          ? `${resetRedirectPath}${resetRedirectPathParam ?? ''}`
          : formattedRedirectPath,
      })
    );
  }, [
    redirectPath,
    dispatch,
    resetRedirectPath,
    resetRedirectPathParam,
    ignoreRedirect,
  ]);

  useEffect(() => {
    if (authenticate && referrer && emailParam) {
      setIgnoreRedirect(true);
      dispatch(
        storeGenericQuestionnaireAnswer('userCreation', {
          redirectPath: `${referrer}${referrerParam ?? ''}`,
        })
      );
    }
  }, [authenticate, referrer, emailParam, dispatch, referrerParam]);

  if (Session.isAuthenticated) {
    return (
      <Redirect
        to={questionnaireAnswers?.redirectPath ?? routes.me.policies.path}
      />
    );
  }

  return (
    <Switch>
      <Route path={routes.account.signIn.questionnaire.path}>
        <Route
          exact
          path={routes.account.signIn.path}
          component={() => (
            <Redirect to={routes.account.signIn.questionnaire.path} />
          )}
        />
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: SignInComponents,
          }}
          basePath={routes.account.signIn.path}
          questionId="email"
          featureName="SignIn"
        />
      </Route>
    </Switch>
  );
};
