import { TFunction } from '@getpopsure/i18n-react';
import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { AppDispatch, RequestAction } from 'constants/actions';
import dayjs from 'dayjs';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { InsuranceTypes } from 'models/insurances/types';
import { AppState } from 'reducers';
import { ThunkAction } from 'redux-thunk';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

export type Transform = {
  personalInfo: string[];
  tariffInfo: string[];
};

export type AutoStartDateType = 'START_OF_NEXT_MONTH' | 'NONE';

const getResult = (result: unknown) => {
  if (result === 'true' || result === 'TRUE') {
    return true;
  }
  if (result === 'false' || result === 'FALSE') {
    return false;
  }
  return result;
};

const transformQuestionnaire = (
  transform: Transform,
  questionnaireAnswers: QuestionnaireQuestions
) => {
  return {
    personalInfo: transform.personalInfo.reduce((acc, key) => {
      acc[key] = getResult(questionnaireAnswers[key]);
      return acc;
    }, {} as Record<string, unknown>),
    tariffInfo: transform.tariffInfo.reduce((acc, key) => {
      const result = getResult(questionnaireAnswers[key]);
      acc[key] = result;
      if (key === 'quote' && typeof result === 'object') {
        return { ...acc, ...result };
      }
      return acc;
    }, {} as Record<string, unknown>),
  };
};

export const createPostQuoteV2 =
  ({
    insuranceType,
    t,
    questionnaireAnswers,
    meta,
    transform,
    autoStartDate,
    uploadTokens,
  }: {
    insuranceType: string;
    t: TFunction;
    questionnaireAnswers: QuestionnaireQuestions;
    transform?: Transform;
    meta: Record<string, unknown>;
    autoStartDate?: AutoStartDateType;
    uploadTokens?: string[];
  }): ThunkAction<
    Promise<{
      status: ActionResponse;
    }>,
    AppState,
    Record<string, unknown>,
    RequestAction
  > =>
  async (dispatch: AppDispatch) => {
    try {
      // Quote validation
      if (!questionnaireAnswers.quote) {
        throw new Error(
          `Quote is not defined. Please try again from the beginning.`
        );
      }

      const validatedQuote = questionnaireAnswers.quote as Record<
        string,
        unknown
      >;

      dispatch(setRequestInProcess(true, 'CREATE_POST_QUOTE_SUBMIT_INFO'));

      const {
        email,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name: { firstName, lastName },
      } = questionnaireAnswers;

      // check if the account exists and create a new account if needed
      const {
        data: { userExists },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            email,
          })
        );
      }

      const source = getTrackingObject();
      // prepare questionnaire data

      const answers = transform
        ? transformQuestionnaire(transform, {
            ...questionnaireAnswers,
            ...(validatedQuote ?? {}),
          })
        : questionnaireAnswers;

      const startDate = {
        START_OF_NEXT_MONTH: dayjs()
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        NONE: undefined,
      }?.[autoStartDate || 'NONE'];

      if (startDate && answers?.personalInfo) {
        answers.personalInfo = {
          ...(typeof answers.personalInfo === 'object'
            ? answers.personalInfo
            : {}),
          startDate,
        };
      }

      const {
        data: { quoteId, questionnaireId },
      } = await endpoint.network.post<{
        quoteId: string;
        questionnaireId: string;
      }>('/signups/pre-checkout', {
        questionnaire: {
          answers,
          questionnaireType: insuranceType,
          metas: { source, ...meta },
          uploadTokens: uploadTokens || [],
        },
        quote: validatedQuote,
      });

      await generateCheckoutDocuments(
        questionnaireId,
        quoteId as string,
        insuranceType as InsuranceTypes,
        t
      );

      dispatch(setRequestInProcess(false, 'CREATE_POST_QUOTE_SUBMIT_INFO'));
      return {
        status: 'SUCCESS',
        result: {
          quoteId,
          questionnaireId,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          quote: { ...validatedQuote, id: quoteId },
          ...(startDate ? { startDate } : {}),
        },
      };
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'CREATE_POST_QUOTE_SUBMIT_INFO'
        )
      );
      Sentry.captureException(error, {
        tags: {
          feature: 'POST_QUOTE_SUBMISSION',
          vertical: insuranceType,
        },
        extra: {
          description: 'Failed to submit with',
        },
      });
      return { status: 'ERROR' };
    }
  };

export const createPostQuote =
  ({
    insuranceType,
    t,
    questionnaireAnswers,
    apiPath,
    meta,
    transform,
    autoStartDate,
    uploadTokens,
  }: {
    insuranceType: string;
    t: TFunction;
    questionnaireAnswers: QuestionnaireQuestions;
    apiPath: string;
    transform?: Transform;
    meta: Record<string, unknown>;
    autoStartDate?: AutoStartDateType;
    uploadTokens?: string[];
  }): ThunkAction<
    Promise<{
      status: ActionResponse;
    }>,
    AppState,
    Record<string, unknown>,
    RequestAction
  > =>
  async (dispatch: AppDispatch) => {
    try {
      // Quote validation
      if (!questionnaireAnswers.quote) {
        throw new Error(
          `Quote is not defined. Please try again from the beginning.`
        );
      }

      const validatedQuote = questionnaireAnswers.quote as Record<
        string,
        unknown
      >;
      const { data } = await endpoint.network.post<QuestionnaireQuestions>(
        apiPath,
        validatedQuote
      );

      const { id: quoteId, price } = data;

      dispatch(setRequestInProcess(true, 'CREATE_POST_QUOTE_SUBMIT_INFO'));

      const {
        email,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name: { firstName, lastName },
      } = questionnaireAnswers;

      // check if the account exists and create a new account if needed
      const {
        data: { userExists },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            email,
          })
        );
      }

      const source = getTrackingObject();
      /* 
      const uploadTokens = questionnaireAnswers.tariffInfo.receipts
        ?.map((file) => file.token)
        .filter((token) => token !== undefined); */

      // prepare questionnaire data

      const answers = transform
        ? transformQuestionnaire(transform, {
            ...questionnaireAnswers,
            ...(validatedQuote ?? {}),
          })
        : questionnaireAnswers;

      const startDate = {
        START_OF_NEXT_MONTH: dayjs()
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        NONE: undefined,
      }?.[autoStartDate || 'NONE'];

      if (startDate && answers?.personalInfo) {
        answers.personalInfo = {
          ...(typeof answers.personalInfo === 'object'
            ? answers.personalInfo
            : {}),
          startDate,
        };
      }

      const {
        data: { id: questionnaireId },
      } = await endpoint.network.post<{
        id: string;
        answers: QuestionnaireQuestions;
      }>('/questionnaires/', {
        answers,
        questionnaireType: insuranceType,
        uploadTokens: uploadTokens || [],
        metadata: { source, ...meta },
      });

      await generateCheckoutDocuments(
        questionnaireId,
        quoteId as string,
        insuranceType as InsuranceTypes,
        t
      );

      dispatch(setRequestInProcess(false, 'CREATE_POST_QUOTE_SUBMIT_INFO'));
      return {
        status: 'SUCCESS',
        result: {
          quoteId,
          questionnaireId,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          quote: { ...validatedQuote, id: quoteId, price },
          ...(startDate ? { startDate } : {}),
        },
      };
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'CREATE_POST_QUOTE_SUBMIT_INFO'
        )
      );
      Sentry.captureException(error, {
        tags: {
          feature: 'POST_QUOTE_SUBMISSION',
          vertical: insuranceType,
        },
        extra: {
          description: 'Failed to submit with',
        },
      });
      return { status: 'ERROR' };
    }
  };
