import { Region } from '@getpopsure/public-models';
import { Button, Radio } from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { QuestionnaireMeta } from 'features/generalQuestionnaire/model';
import { ReactNode, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import styles from './styles.module.scss';

interface VariantPickerProps {
  meta: QuestionnaireMeta;
}

export const VariantPicker = ({ meta }: VariantPickerProps) => {
  const history = useHistory();
  const { regionId } = useParams<{
    regionId: Region;
  }>();

  const [selectedVariant, setSelectedVariant] = useState<string | undefined>(
    undefined
  );

  const options = (meta.variants || []).reduce((acc, { slug, name }) => {
    acc[slug] = {
      title: name,
    };
    return acc;
  }, {} as Record<string, ReactNode>);

  const handleContinue = () =>
    history.push(
      generatePath(routes.birdhouse.preview.policy.questionnaire.path, {
        regionId,
        verticalId: `${meta.id}-${selectedVariant}`,
      })
    );

  return (
    <>
      <div className={styles.container}>
        <div className="p-body">
          <h1 className="p-h1 mt8">Pick a variant</h1>
          <p className="p-p mt8">
            This screen is only available on staging/test environments
          </p>
          <div className="mt16 w100 wmx6">
            <Radio
              options={options}
              value={selectedVariant}
              onChange={setSelectedVariant}
              inlineIcon
            />
          </div>

          <div className="d-flex f-wrap mt24 wmx6 w100">
            <Button
              className="w100"
              disabled={!selectedVariant}
              onClick={handleContinue}
              data-cy="button-continue"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
