import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import Session from '@getpopsure/session';
import * as Sentry from '@sentry/react';
import { ErrorWithAction } from 'components/ErrorWithAction';
import LoadingSpinner from 'components/loadingSpinner';
import { useGetPolicies } from 'hooks/useGetPolicies';
import { InsuranceStatus } from 'models/insurances/status';
import { InsuranceTypes } from 'models/insurances/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getEmail } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';

const config: Partial<
  Record<
    InsuranceTypes,
    {
      activeInsuranceStatus: InsuranceStatus[];
      pendingInsuranceStatus: InsuranceStatus[];
    }
  >
> = {
  PUBLIC_HEALTH: {
    activeInsuranceStatus: ['COVERED'],
    pendingInsuranceStatus: [
      'APPLICATION_RECEIVED',
      'APPLICATION_SENT',
      'ERR_MISSING_INFO',
      'PROCESSING',
    ],
  },
};

export const HasPolicy = <Questionnaire extends QuestionnaireQuestions>({
  insuranceType,
  questionnaireAnswers,
  onSubmitValue,
}: QuestionnaireFormProps<Questionnaire> & {
  insuranceType: InsuranceTypes;
}) => {
  const { isAuthenticated } = Session;
  const [hasFetchedPolicies, setHasFetchedPolicies] = useState(false);
  const email = useSelector(getEmail);
  const { t } = useSafeTranslation();
  const history = useHistory();
  const { policies, isLoading, isSuccess, error } = useGetPolicies(() =>
    setHasFetchedPolicies(true)
  );

  useEffect(() => {
    if (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'HAS_POLICY',
        },
        extra: {
          description: `Error happened while fetching policies for hasPolicy component: SignupQuestionnaire`,
        },
      });
    }
  }, [error]);

  useEffect(() => {
    if (
      !isAuthenticated ||
      email !== questionnaireAnswers.email ||
      (!policies && !isSuccess)
    ) {
      onSubmitValue(['NO_POLICIES'], {}, { replaceState: true });
      return;
    }

    if (isLoading || !hasFetchedPolicies) {
      return;
    }

    const typeConfig = config?.[insuranceType];

    if (!policies?.length || !typeConfig) {
      onSubmitValue(['NO_POLICIES'], {}, { replaceState: true });
      return;
    }

    const policiesWithType = policies.filter(
      ({ type }) => type === insuranceType
    );

    const hasActivePolicy = policiesWithType.some(({ status }) =>
      typeConfig.activeInsuranceStatus.includes(status)
    );

    const hasPendingPolicy = policiesWithType.some(({ status }) =>
      typeConfig.pendingInsuranceStatus.includes(status)
    );

    onSubmitValue(
      [
        ...(hasActivePolicy ? ['ACTIVE'] : []),
        ...(hasPendingPolicy ? ['PENDING'] : []),
      ],
      {},
      { replaceState: true }
    );
  }, [
    policies,
    isLoading,
    onSubmitValue,
    insuranceType,
    isAuthenticated,
    email,
    questionnaireAnswers.email,
    isSuccess,
    hasFetchedPolicies,
  ]);

  if (error) {
    return (
      <ErrorWithAction
        title={t(
          'signupQuestionnaire.hasPolicy.error.title',
          'Something went wrong'
        )}
        description={t(
          'signupQuestionnaire.hasPolicy.error.description',
          'Sorry, something didn’t work as it should.'
        )}
        cta={{
          title: t('signupQuestionnaire.hasPolicy.error.cta', 'Go back'),
          onClick: () => history.goBack(),
        }}
      />
    );
  }

  return <LoadingSpinner />;
};
