import {
  basisPension as basisPensionImg,
  basisPension2x as basisPensionImg2x,
  basisPension3x as basisPensionImg3x,
  bike as bikeImg,
  bike2x as bikeImg2x,
  bike3x as bikeImg3x,
  car as carImg,
  car2x as carImg2x,
  car3x as carImg3x,
  dental as dentalImg,
  dental2x as dentalImg2x,
  dental3x as dentalImg3x,
  disability as disabilityImg,
  disability2x as disabilityImg2x,
  disability3x as disabilityImg3x,
  dogLiability as dogLiabilityImg,
  dogLiability2x as dogLiabilityImg2x,
  dogLiability3x as dogLiabilityImg3x,
  expat as expatImg,
  expat2x as expatImg2x,
  expat3x as expatImg3x,
  generic as genericImg,
  generic2x as genericImg2x,
  generic3x as genericImg3x,
  health as companyHealthImg,
  health2x as companyHealthImg2x,
  health3x as companyHealthImg3x,
  household as householdImg,
  household2x as householdImg2x,
  household3x as householdImg3x,
  job as jobImg,
  job2x as jobImg2x,
  job3x as jobImg3x,
  legal as legalImg,
  legal2x as legalImg2x,
  legal3x as legalImg3x,
  liability as liabilityImg,
  liability2x as liabilityImg2x,
  liability3x as liabilityImg3x,
  life as lifeImg,
  life2x as lifeImg2x,
  life3x as lifeImg3x,
  pension as pensionImg,
  pension2x as pensionImg2x,
  pension3x as pensionImg3x,
  petHealth as petHealthImg,
  petHealth2x as petHealthImg2x,
  petHealth3x as petHealthImg3x,
  privateHealth as privateHealthImg,
  privateHealth2x as privateHealthImg2x,
  privateHealth3x as privateHealthImg3x,
  publicHealth as publicHealthImg,
  publicHealth2x as publicHealthImg2x,
  publicHealth3x as publicHealthImg3x,
  travel as travelImg,
  travel2x as travelImg2x,
  travel3x as travelImg3x,
} from 'models/insurances/assets/img';
import { InsuranceTypes } from 'models/insurances/types';
import { TFunction } from 'shared/i18n';
import { getSrcSet } from 'shared/util/getSrcSet';

export const getTitleMapping: (
  t: TFunction
) => Record<InsuranceTypes, string> = (t) => ({
  HOUSEHOLD: t('models.insuranceType.titleMapping.household', 'Household'),

  LIABILITY: t('models.insuranceType.titleMapping.liability', 'Liability'),
  PUBLIC_HEALTH: t(
    'models.insuranceType.titleMapping.publicHealth',
    'Public health'
  ),
  PRIVATE_HEALTH: t(
    'models.insuranceType.titleMapping.privateHealth',
    'Private health'
  ),
  CAR: t('models.insuranceType.titleMapping.car', 'Car'),
  JOB: t('models.insuranceType.titleMapping.job', 'Job'),
  LIFE: t('models.insuranceType.titleMapping.life', 'Life'),
  LIFE_V2: t('models.insuranceType.titleMapping.life', 'Life'),
  INCOMING: t('models.insuranceType.titleMapping.expat', 'Expat health'),
  EXPAT_V2: t('models.insuranceType.titleMapping.expat', 'Expat health'),
  LEGAL: t('models.insuranceType.titleMapping.legal', 'Legal'),
  LEGAL_V2: t('models.insuranceType.titleMapping.legal', 'Legal'),
  DENTAL: t('models.insuranceType.titleMapping.dental', 'Dental'),
  DENTAL_V2: t('models.insuranceType.titleMapping.dental', 'Dental'),
  DENTAL_ES: t('models.insuranceType.titleMapping.dentalSpain', 'Dental'),
  BIKE: t('models.insuranceType.titleMapping.bike', 'Bike'),
  DOG_LIABILITY: t(
    'models.insuranceType.titleMapping.dogLiability',
    'Dog liability'
  ),
  DISABILITY: t('models.insuranceType.titleMapping.disability', 'Disability'),
  TRAVEL: t('models.insuranceType.titleMapping.travel', 'Travel'),
  PET_HEALTH: t('models.insuranceType.titleMapping.petHealth', 'Pet health'),
  GENERIC: t('models.insuranceType.titleMapping.generic', 'Generic'),
  COMPANY_LIFE: t(
    'models.insuranceType.titleMapping.companyLife',
    'Company life'
  ),
  COMPANY_HEALTH: t(
    'models.insuranceType.titleMapping.companyHealth',
    'Company health'
  ),
  PENSION: t('models.insuranceType.titleMapping.pension', 'Pension'),
  BASIS_PENSION: t(
    'models.insuranceType.titleMapping.basisPension',
    'Basis pension'
  ),
  INCOMING_ES: t(
    'models.insuranceType.titleMapping.expatSpain',
    'Expat health'
  ),
  TRAVEL_HEALTH_V1: t(
    'models.insuranceType.titleMapping.travelHealthV1',
    'travelHealthV1'
  ),
  TRAVEL_FR: t('models.insuranceType.titleMapping.travelFrance', 'Travel'),
  BIKE_V2: t('models.insuranceType.titleMapping.bike', 'Bike'),
  INCOMING_EU: t('models.insuranceType.titleMapping.expatEu', 'Expat health'),
  INCOMING_LT: t(
    'models.insuranceType.titleMapping.expatLongTerm',
    'Expat health long-term'
  ),
  DISABILITY_V2: t(
    'models.insuranceType.titleMapping.disabilityV2',
    'disabilityV2'
  ),
  COMPANY_PENSION: t(
    'models.insuranceType.titleMapping.companyPension',
    'Company pension'
  ),
  DOG_LIABILITY_ES: t(
    'models.insuranceType.titleMapping.dogLiabilitySpain',
    'Dog liability'
  ),
  BIKE_ES: t('models.insuranceType.titleMapping.bikeSpain', 'Bike'),
  LIFE_EU: t('models.insuranceType.titleMapping.lifeEu', 'Life'),
  HOME_FR: t('models.insuranceType.titleMapping.homeFrance', 'Home'),
});

export const getExcerptMapping: (
  t: TFunction
) => Record<InsuranceTypes, string> = (t) => ({
  HOUSEHOLD: t(
    'models.insuranceType.excerpt.household',
    'Coverage for your valuables against fires, robberies, etc.'
  ),

  LIABILITY: t(
    'models.insuranceType.excerpt.liability',
    'Protection for damage you cause to people or their belongings'
  ),
  PUBLIC_HEALTH: t(
    'models.insuranceType.excerpt.publicHealth',
    'Choose from one of the four biggest public health providers'
  ),
  PRIVATE_HEALTH: t(
    'models.insuranceType.excerpt.privateHealth',
    'Comprehensive coverage for those who want a little extra'
  ),
  CAR: t(
    'models.insuranceType.excerpt.car',
    'Protection in the case of a car accident'
  ),
  JOB: '',
  LIFE: t(
    'models.insuranceType.excerpt.life',
    'Support for your loved ones in the event of your passing'
  ),
  LIFE_V2: t(
    'models.insuranceType.excerpt.life',
    'Support for your loved ones in the event of your passing'
  ),
  INCOMING: t(
    'models.insuranceType.excerpt.expat',
    'Health insurance for those new to Germany'
  ),
  EXPAT_V2: t(
    'models.insuranceType.excerpt.expat',
    'Health insurance for those new to Germany'
  ),
  LEGAL: t(
    'models.insuranceType.excerpt.legal',
    'Covers the cost of disputes over employment, contracts, and more.'
  ),
  LEGAL_V2: t(
    'models.insuranceType.excerpt.legal',
    'Covers the cost of disputes over employment, contracts, and more.'
  ),
  DENTAL: t(
    'models.insuranceType.excerpt.dental',
    'Comprehensive dental coverage'
  ),
  DENTAL_V2: t(
    'models.insuranceType.excerpt.dental',
    'Comprehensive dental coverage'
  ),
  DENTAL_ES: t(
    'models.insuranceType.excerpt.dentalSpain',
    'Comprehensive dental coverage'
  ),
  BIKE: t(
    'models.insuranceType.excerpt.bike',
    'Coverage for bikes in the case of theft, damages, and more'
  ),
  DOG_LIABILITY: t(
    'models.insuranceType.excerpt.dogLiability',
    'Covers damage caused by dogs to others or their belongings'
  ),
  DISABILITY: t(
    'models.insuranceType.excerpt.disability',
    'Replaces lost income if you’re unable to work'
  ),
  TRAVEL: t(
    'models.insuranceType.excerpt.travel',
    'Health and trip cancellation coverage while traveling abroad'
  ),
  PET_HEALTH: t(
    'models.insuranceType.excerpt.petHealth',
    'Coverage for vet appointments and treatments for pets'
  ),
  GENERIC: '',
  COMPANY_LIFE: '',
  COMPANY_HEALTH: '',
  PENSION: t(
    'models.insuranceType.excerpt.pension',
    'Personal pension plan for a secured retirement'
  ),
  BASIS_PENSION: t(
    'models.insuranceType.excerpt.basisPension',
    'Personal pension plan for a secured retirement'
  ),
  INCOMING_ES: t(
    'models.insuranceType.excerpt.expatSpain',
    'Health insurance for those new to Spain'
  ),
  TRAVEL_HEALTH_V1: t(
    'models.insuranceType.excerpt.travelHealthV1',
    'travelHealthV1'
  ),
  TRAVEL_FR: t(
    'models.insuranceType.excerpt.travelFrance',
    'Health and trip cancellation coverage while traveling abroad'
  ),
  BIKE_V2: t(
    'models.insuranceType.excerpt.bike',
    'Coverage for bikes in the case of theft, damages, and more'
  ),
  INCOMING_EU: t(
    'models.insuranceType.excerpt.expatEu',
    'Expat health insurance (sometimes referred to as “incoming insurance”) is a great solution for your first five years in Europe.'
  ),
  INCOMING_LT: '',
  DISABILITY_V2: t('models.insuranceType.excerpt.disabilityV2', 'disabilityV2'),
  COMPANY_PENSION: '',
  DOG_LIABILITY_ES: t(
    'models.insuranceType.excerpt.dogLiabilitySpain',
    'dogLiabilitySpain'
  ),
  BIKE_ES: t(
    'models.insuranceType.excerpt.bikeSpain',
    'Coverage for bikes in the case of theft, damages, and more'
  ),
  LIFE_EU: t(
    'models.insuranceType.excerpt.lifeEU',
    'Support for your loved ones in the event of your passing'
  ),
  HOME_FR: t(
    'models.insuranceType.excerpt.homeFrance',
    'Coverage for your valuables against damage, theft, natural disasters, and much more.'
  ),
});

export const getDescriptionMapping: (
  t: TFunction
) => Record<InsuranceTypes, string> = (t) => ({
  HOUSEHOLD: t(
    'models.insuranceType.description.household',
    'Household contents insurance covers any belongings in your home that aren’t attached in some way to the building. This can include things like furniture, dishes, electronics, clothing, books, and other belongings. You’re covered in case of a break-in, fire, water damage, or other unexpected events where your belongings might be damaged or stolen.'
  ),
  LIABILITY: t(
    'models.insuranceType.description.liability',
    "Liability insurance covers damage you do to other people’s belongings or if you injure someone. That includes the injured person's hospital bills and any missed wages from being unable to work. Because of how costly this can get, around 80% of all Germans have liability insurance. Additionally, liability insurance will cover damages you’ve done to a flat you’re renting, like scratches on the floor from small animals as well as the cost to replace lost keys."
  ),
  PUBLIC_HEALTH: t(
    'models.insuranceType.description.publicHealth',
    'Public health insurance is state insurance where members along with the government contribute to more than 100 individual not-for-profit public health funds. Each provider / public fund offers comprehensive coverage to all of their members. This includes an extensive range of preventative services, acute care, and full or partial reimbursement for medications.'
  ),
  PRIVATE_HEALTH: t(
    'models.insuranceType.description.privateHealth',
    'Private health insurance provides the best coverage in Germany. This includes coveted fertility treatments, a budget for chiropractors, vision insurance, private rooms when staying overnight at a hospital, and many other perks that public insurance cannot offer.'
  ),
  CAR: t(
    'models.insuranceType.description.car',
    'Car insurance offers both liability protection and vehicle protection. The liability protection pays for the damage you cause to third-party vehicles, or other property (e.g., buildings or traffic facilities) as well as for personal injury and financial loss. Vehicle protection covers the damage caused to your vehicle by theft, weather, glass breakage, and wildlife-related accidents. The coverage can be extended to also cover damage caused by a collision with another vehicle, as well as unintentional self-inflicted damage and vandalism.'
  ),
  JOB: '',
  LIFE: t(
    'models.insuranceType.description.life',
    'Life insurance is a policy that pays out a fixed sum (of your choosing) if you pass away. Coverage is generally used by dependents and loved ones to pay for the cost of living until they regain financial stability, and includes a spouse, child, or even a business partner.'
  ),
  LIFE_V2: t(
    'models.insuranceType.description.life',
    'Life insurance is a policy that pays out a fixed sum (of your choosing) if you pass away. Coverage is generally used by dependents and loved ones to pay for the cost of living until they regain financial stability, and includes a spouse, child, or even a business partner.'
  ),
  INCOMING: t(
    'models.insuranceType.description.expat',
    'Expat health insurance, also known as incoming insurance, covers the basics until you can get on a private or public plan. You’re only eligible for expat insurance for five years after entering the country, so you’ll need to find a more permanent solution if you plan on renewing your visa. This insurance also won’t work if your visa’s validity will last longer than the plan.'
  ),
  EXPAT_V2: t(
    'models.insuranceType.description.expat',
    'Expat health insurance, also known as incoming insurance, covers the basics until you can get on a private or public plan. You’re only eligible for expat insurance for five years after entering the country, so you’ll need to find a more permanent solution if you plan on renewing your visa. This insurance also won’t work if your visa’s validity will last longer than the plan.'
  ),
  LEGAL: t(
    'models.insuranceType.description.legal',
    'Legal insurance offers protection in five areas: disorderly conduct, automotive, professional, private life, and real estate disputes. This provides you with comprehensive legal coverage for most situations from disturbing the neighbors with loud music (disorderly conduct), getting into a car accident (automotive), disputing an unlawful termination (professional), identity theft (private life), and illegal increases in rent (real estate).'
  ),
  LEGAL_V2: t(
    'models.insuranceType.description.legal',
    'Legal insurance offers protection in five areas: disorderly conduct, automotive, professional, private life, and real estate disputes. This provides you with comprehensive legal coverage for most situations from disturbing the neighbors with loud music (disorderly conduct), getting into a car accident (automotive), disputing an unlawful termination (professional), identity theft (private life), and illegal increases in rent (real estate).'
  ),
  DENTAL: t(
    'models.insuranceType.description.dental',
    'Dental insurance is an extension for public health insurance and covers the additional needs you might have for your teeth (with no waiting times). This includes high-quality fillings, surgeries for root canals, and professional cleanings. We also offer an advanced plan that covers teeth replacement surgeries, and has a whitening allowance.'
  ),
  DENTAL_V2: t(
    'models.insuranceType.description.dental',
    'Dental insurance is an extension for public health insurance and covers the additional needs you might have for your teeth (with no waiting times). This includes high-quality fillings, surgeries for root canals, and professional cleanings. We also offer an advanced plan that covers teeth replacement surgeries, and has a whitening allowance.'
  ),
  DENTAL_ES: '',
  BIKE: t(
    'models.insuranceType.description.bike',
    'Bike insurance offers protection for newly purchased bikes less than 6 months old. Our coverage protects both e-bikes and normal bikes against theft (including parts and battery). You can extend your coverage to include vandalism, production errors, and even wear and tear.'
  ),
  DOG_LIABILITY: t(
    'models.insuranceType.description.dogLiability',
    "Dog liability insurance pays for the damage your dog causes to third parties or their belongings. As a dog owner, you are legally responsible for your dog's actions, and the insurance is mandatory in some regions of Germany (Berlin, Brandenburg, Hamburg, Lower Saxony, Thuringia and Schleswig-Holstein)."
  ),
  DISABILITY: t(
    'models.insuranceType.description.disability',
    'Disability insurance provides financial protection by replacing lost income in case of a disability that impacts your capacity to work. Examples are psychosomatic illnesses, accidents, cancer, and heart disease. Disability affects around 25% of people for a least 6 months and is paid out until the person is recovered and can work in their previous field or until retirement.'
  ),
  DISABILITY_V2: t(
    'models.insuranceType.description.disability',
    'Disability insurance provides financial protection by replacing lost income in case of a disability that impacts your capacity to work. Examples are psychosomatic illnesses, accidents, cancer, and heart disease. Disability affects around 25% of people for a least 6 months and is paid out until the person is recovered and can work in their previous field or until retirement.'
  ),
  TRAVEL: t(
    'models.insuranceType.description.travel',
    "Travel health insurance covers medical expenses abroad for an unlimited number of trips per year, up to 56 days each. It is visa-compliant and covers dental care, hospital treatments, rescue and repatriation, doctor's visits, medical prescriptions, and pregnancy complications."
  ),
  PET_HEALTH: '',
  GENERIC: '',
  COMPANY_LIFE: '',
  COMPANY_HEALTH: '',
  COMPANY_PENSION: '',
  PENSION: '',
  BASIS_PENSION: '',
  INCOMING_ES: '',
  TRAVEL_HEALTH_V1: '',
  TRAVEL_FR: '',
  BIKE_V2: t(
    'models.insuranceType.description.bike',
    'Bike insurance offers protection for newly purchased bikes less than 6 months old. Our coverage protects both e-bikes and normal bikes against theft (including parts and battery). You can extend your coverage to include vandalism, production errors, and even wear and tear.'
  ),
  INCOMING_EU: t(
    'models.insuranceType.description.expatEu',
    'Expat health insurance (sometimes referred to as “incoming insurance”) is a great solution for your first five years in Europe.'
  ),
  INCOMING_LT: '',
  DOG_LIABILITY_ES: t(
    'models.insuranceType.description.dogLiabilitySpain',
    "Dog liability insurance pays for the damage your dog causes to third parties or their belongings. As a dog owner, you are legally responsible for your dog's actions, and the insurance is mandatory in some regions of Germany (Berlin, Brandenburg, Hamburg, Lower Saxony, Thuringia and Schleswig-Holstein)."
  ),
  BIKE_ES: t(
    'models.insuranceType.description.bikeSpain',
    'Bike insurance offers protection for newly purchased bikes less than 6 months old. Our coverage protects both e-bikes and normal bikes against theft (including parts and battery). You can extend your coverage to include vandalism, production errors, and even wear and tear.'
  ),
  LIFE_EU: t(
    'models.insuranceType.description.lifeEu',
    'Life insurance is a policy that pays out a fixed sum (of your choosing) if you pass away. Coverage is generally used by dependents and loved ones to pay for the cost of living until they regain financial stability, and includes a spouse, child, or even a business partner.'
  ),
  HOME_FR: '',
});

export const imageTypeMapping: Record<InsuranceTypes, string> = {
  HOUSEHOLD: householdImg,
  HOME_FR: householdImg,
  PUBLIC_HEALTH: publicHealthImg,
  PRIVATE_HEALTH: privateHealthImg,
  LIABILITY: liabilityImg,
  LIFE: lifeImg,
  LIFE_V2: lifeImg,
  INCOMING: expatImg,
  EXPAT_V2: expatImg,
  LEGAL: legalImg,
  LEGAL_V2: legalImg,
  DENTAL: dentalImg,
  DENTAL_V2: dentalImg,
  DENTAL_ES: dentalImg,
  BIKE: bikeImg,
  COMPANY_LIFE: lifeImg,
  COMPANY_HEALTH: companyHealthImg,
  CAR: carImg,
  JOB: jobImg,
  DOG_LIABILITY: dogLiabilityImg,
  PET_HEALTH: petHealthImg,
  DISABILITY: disabilityImg,
  TRAVEL: travelImg,
  GENERIC: genericImg,
  PENSION: pensionImg,
  COMPANY_PENSION: pensionImg,
  BASIS_PENSION: basisPensionImg,
  INCOMING_ES: expatImg,
  TRAVEL_HEALTH_V1: genericImg,
  TRAVEL_FR: travelImg,
  BIKE_V2: bikeImg,
  INCOMING_EU: expatImg,
  INCOMING_LT: expatImg,
  DISABILITY_V2: disabilityImg,
  DOG_LIABILITY_ES: dogLiabilityImg,
  BIKE_ES: bikeImg,
  LIFE_EU: lifeImg,
};

export const imageSrcSetTypeMapping: Record<
  InsuranceTypes,
  string | undefined
> = {
  HOUSEHOLD: getSrcSet([householdImg, householdImg2x, householdImg3x]),
  HOME_FR: getSrcSet([householdImg, householdImg2x, householdImg3x]),
  PUBLIC_HEALTH: getSrcSet([
    publicHealthImg,
    publicHealthImg2x,
    publicHealthImg3x,
  ]),
  PRIVATE_HEALTH: getSrcSet([
    privateHealthImg,
    privateHealthImg2x,
    privateHealthImg3x,
  ]),
  LIABILITY: getSrcSet([liabilityImg, liabilityImg2x, liabilityImg3x]),
  LIFE: getSrcSet([lifeImg, lifeImg2x, lifeImg3x]),
  LIFE_V2: getSrcSet([lifeImg, lifeImg2x, lifeImg3x]),
  INCOMING: getSrcSet([expatImg, expatImg2x, expatImg3x]),
  EXPAT_V2: getSrcSet([expatImg, expatImg2x, expatImg3x]),
  LEGAL: getSrcSet([legalImg, legalImg2x, legalImg3x]),
  LEGAL_V2: getSrcSet([legalImg, legalImg2x, legalImg3x]),
  DENTAL: getSrcSet([dentalImg, dentalImg2x, dentalImg3x]),
  DENTAL_V2: getSrcSet([dentalImg, dentalImg2x, dentalImg3x]),
  DENTAL_ES: getSrcSet([dentalImg, dentalImg2x, dentalImg3x]),
  BIKE: getSrcSet([bikeImg, bikeImg2x, bikeImg3x]),
  COMPANY_LIFE: getSrcSet([lifeImg, lifeImg2x, lifeImg3x]),
  COMPANY_HEALTH: getSrcSet([
    companyHealthImg,
    companyHealthImg2x,
    companyHealthImg3x,
  ]),
  CAR: getSrcSet([carImg, carImg2x, carImg3x]),
  JOB: getSrcSet([jobImg, jobImg2x, jobImg3x]),
  DOG_LIABILITY: getSrcSet([
    dogLiabilityImg,
    dogLiabilityImg2x,
    dogLiabilityImg3x,
  ]),
  PET_HEALTH: getSrcSet([petHealthImg, petHealthImg2x, petHealthImg3x]),
  DISABILITY: getSrcSet([disabilityImg, disabilityImg2x, disabilityImg3x]),
  DISABILITY_V2: getSrcSet([disabilityImg, disabilityImg2x, disabilityImg3x]),
  TRAVEL: getSrcSet([travelImg, travelImg2x, travelImg3x]),
  GENERIC: getSrcSet([genericImg, genericImg2x, genericImg3x]),
  PENSION: getSrcSet([pensionImg, pensionImg2x, pensionImg3x]),
  BASIS_PENSION: getSrcSet([
    basisPensionImg,
    basisPensionImg2x,
    basisPensionImg3x,
  ]),
  INCOMING_ES: getSrcSet([expatImg, expatImg2x, expatImg3x]),
  TRAVEL_HEALTH_V1: getSrcSet([genericImg, genericImg2x, genericImg3x]),
  TRAVEL_FR: getSrcSet([travelImg, travelImg2x, travelImg3x]),
  BIKE_V2: getSrcSet([bikeImg, bikeImg2x, bikeImg3x]),
  INCOMING_EU: getSrcSet([expatImg, expatImg2x, expatImg3x]),
  INCOMING_LT: getSrcSet([expatImg, expatImg2x, expatImg3x]),
  COMPANY_PENSION: getSrcSet([pensionImg, pensionImg2x, pensionImg3x]),
  DOG_LIABILITY_ES: getSrcSet([
    dogLiabilityImg,
    dogLiabilityImg2x,
    dogLiabilityImg3x,
  ]),
  BIKE_ES: getSrcSet([bikeImg, bikeImg2x, bikeImg3x]),
  LIFE_EU: getSrcSet([lifeImg, lifeImg2x, lifeImg3x]),
};

export const hasAutomatedCancelProcess: { [K in InsuranceTypes]: boolean } = {
  HOUSEHOLD: true,
  HOME_FR: false,
  LIABILITY: true,
  INCOMING: true,
  EXPAT_V2: true,
  PUBLIC_HEALTH: false,
  PRIVATE_HEALTH: false,
  CAR: false,
  JOB: false,
  LIFE: true,
  LIFE_V2: true,
  LEGAL: true,
  LEGAL_V2: true,
  DENTAL: true,
  DENTAL_V2: true,
  DENTAL_ES: false,
  BIKE: false,
  DOG_LIABILITY: false,
  COMPANY_LIFE: false,
  COMPANY_HEALTH: false,
  DISABILITY: false,
  GENERIC: false,
  TRAVEL: false,
  PET_HEALTH: false,
  PENSION: false,
  BASIS_PENSION: false,
  INCOMING_ES: true,
  TRAVEL_HEALTH_V1: false,
  TRAVEL_FR: false,
  BIKE_V2: false,
  INCOMING_EU: true,
  INCOMING_LT: true,
  DISABILITY_V2: false,
  COMPANY_PENSION: false,
  DOG_LIABILITY_ES: false,
  BIKE_ES: false,
  LIFE_EU: false,
};

export const bannerColorMapping: Record<InsuranceTypes, string> = {
  HOUSEHOLD: '#FAEFDD',
  HOME_FR: '#FAEFDD',
  PUBLIC_HEALTH: '#FFF7EC',
  PRIVATE_HEALTH: '#E4F0FB',
  LIABILITY: '#FAF5EB',
  LIFE: '#FCF1E8',
  LIFE_V2: '#FCF1E8',
  INCOMING: '#E2F3EF',
  EXPAT_V2: '#E2F3EF',
  LEGAL: '#FBF5EC',
  LEGAL_V2: '#FBF5EC',
  DENTAL: '#EAF7F7',
  DENTAL_V2: '#EAF7F7',
  DENTAL_ES: '#EAF7F7',
  BIKE: '#F3FAF5',
  COMPANY_LIFE: '#F5F1E8',
  COMPANY_HEALTH: '#F5F1E8',
  CAR: '#FEF8EB',
  JOB: '#FEF8EB',
  DOG_LIABILITY: '#DFF0EC',
  PET_HEALTH: '#FAEDE7',
  DISABILITY: '#EFE5D3',
  TRAVEL: '#FBF7EC',
  GENERIC: '#FEF8EB',
  PENSION: '#FEF8EB',
  BASIS_PENSION: '#E2F0FA',
  INCOMING_ES: '#E2F3EF',
  TRAVEL_HEALTH_V1: '#FEF8EB',
  TRAVEL_FR: '#FBF7EC',
  BIKE_V2: '#F3FAF5',
  INCOMING_EU: '#E2F3EF',
  INCOMING_LT: '#E2F3EF',
  DISABILITY_V2: '#FEF8EB',
  COMPANY_PENSION: '#FEF8EB',
  DOG_LIABILITY_ES: '#DFF0EC',
  BIKE_ES: '#F3FAF5',
  LIFE_EU: '#FCF1E8',
};
