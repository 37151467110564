import { website } from '@getpopsure/private-constants';
import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { Button, InformationBox } from '@popsure/dirty-swan';
import { setAccountDataInQuestionnaire } from 'actions/account';
import classNames from 'classnames';
import { EmailCheckerInput } from 'components/EmailCheckerInput';
import { GoogleLoginButton } from 'features/GoogleOAuth';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { getRequestError, getRequestInProcess } from 'selectors/requests';
import { getEmail } from 'selectors/user';
import { Trans, useSafeTranslation } from 'shared/i18n';
import { useEmailQuestion } from 'SignupQuestionnaire/components//Email/useEmailQuestion';
import LoginModal from 'SignupQuestionnaire/components/Email/LoginModal';

import styles from './Email.module.scss';

export const Email = <Questionnaire extends QuestionnaireQuestions>({
  value,
  onSubmitValue,
  verticalId,
  setValidForSubmission,
  questionnaireAnswers,
  question,
}: {
  verticalId: keyof GenericQuestionnaireState;
} & QuestionnaireFormProps<Questionnaire>) => {
  const { t } = useSafeTranslation();
  const [googleLoginError, setGoogleLoginError] = useState(false);
  const [isLoginWithGoogle, setIsLoginWithGoogle] = useState(false);
  const [continueWithEmail, setContinueWithEmail] = useState(false);
  const dispatch = useDispatch();
  const accountEmail = useSelector(getEmail);
  const redirectPath =
    typeof questionnaireAnswers.redirectPath === 'string'
      ? questionnaireAnswers.redirectPath
      : '';

  const loadingGoogle = useSelector((state: AppState) =>
    getRequestInProcess(state, 'SET_ACCOUNT_DATA_IN_QUESTIONNAIRE')
  );
  const errorGoogle = useSelector((state: AppState) =>
    getRequestError(state, 'SET_ACCOUNT_DATA_IN_QUESTIONNAIRE')
  );

  useEffect(() => {
    if (!errorGoogle && !loadingGoogle && accountEmail && isLoginWithGoogle) {
      setIsLoginWithGoogle(false);
      onSubmitValue(accountEmail);
    }
  }, [
    errorGoogle,
    loadingGoogle,
    accountEmail,
    onSubmitValue,
    isLoginWithGoogle,
  ]);

  const {
    email,
    handleInputChange,
    handleModalClose,
    handleSubmit,
    isValid,
    onLogin,
    requiresLoginCode,
    toggleTermsAccepted,
  } = useEmailQuestion({
    onSubmitValue,
    setValidForSubmission,
    value,
  });

  const handleOnGoogleLogin = () => {
    dispatch(setAccountDataInQuestionnaire(redirectPath, verticalId));
    setIsLoginWithGoogle(true);
  };

  return (
    <div className="p-body">
      <div className={styles.container}>
        <Header onSubmit={handleSubmit}>
          <h1 className="p-h1">
            {question ||
              t(
                'components.email.titleContinueToFeatherAccount',
                'Let’s continue with a Feather account'
              )}
          </h1>
          <div className="p-p mb24">
            {continueWithEmail
              ? t('components.email.afterClickWithEmail', 'Continue with email')
              : t(
                  'components.email.initial',
                  'We’ll create one if you’re new to Feather.'
                )}
          </div>

          <AnimateHeight duration={300} height={continueWithEmail ? 'auto' : 0}>
            <EmailCheckerInput
              className={classNames(styles.emailInput)}
              type="email"
              value={(value as string) ?? email}
              placeholder={t('components.email.placeholder', 'Email address')}
              onChange={handleInputChange}
              required
              data-cy="input-email"
            />
            <div className="mt16 p-label-container">
              <input
                id="terms"
                className="p-checkbox"
                type="checkbox"
                data-testid="email-tnc"
                onChange={toggleTermsAccepted}
              />
              <label
                htmlFor="terms"
                className={`p-label ${styles.label}`}
                data-cy="email-tnc"
              >
                <div className="p-p">
                  <Trans i18nKey="components.email.agreement">
                    I have read and understood the{' '}
                    <a
                      className="p-a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={website.privacyPolicy}
                    >
                      privacy policy
                    </a>{' '}
                    and{' '}
                    <a
                      className="p-a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={website.termsAndConditions}
                    >
                      T&Cs
                    </a>
                    .
                  </Trans>
                </div>
              </label>
            </div>
          </AnimateHeight>

          <div className={classNames(styles.buttonContainer, 'd-flex gap8')}>
            {continueWithEmail ? (
              <Button
                disabled={!isValid}
                data-cy="button-continue"
                onClick={() => setContinueWithEmail(true)}
              >
                {t('components.email.button', 'Continue')}
              </Button>
            ) : (
              <Button
                className="wmn3"
                data-cy="button-continue"
                onClick={() => setContinueWithEmail(true)}
              >
                {t('components.email.buttonWithEmail', 'Continue with email')}
              </Button>
            )}

            <GoogleLoginButton
              className="wmn3"
              onSuccess={() => handleOnGoogleLogin()}
              onLoginError={() => {
                setIsLoginWithGoogle(false);
                setGoogleLoginError(true);
              }}
            />
          </div>

          <div>
            {googleLoginError && (
              <InformationBox className="mt16 wmx6" variant="error">
                {t(
                  'components.email.googleLoginError',
                  "Your account isn't linked to Google yet - please log in with your email and sign-in code first, then link your Google account in Account settings."
                )}
              </InformationBox>
            )}
          </div>
        </Header>
      </div>

      <LoginModal
        email={email}
        isOpen={requiresLoginCode}
        onClose={handleModalClose}
        onLogin={onLogin}
        verticalId={verticalId}
      />
    </div>
  );
};
