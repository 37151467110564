import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import {
  Card,
  Checkbox,
  InformationBox,
  SignaturePad,
  Spinner,
} from '@popsure/dirty-swan';
import Markdown from 'components/markdown';
import { useUploadFiles } from 'hooks/useUploadFiles';
import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import styles from './styles.module.scss';

function base64ToFile(base64: string, filename: string): File {
  const arr = base64.split(',');
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: 'image/png' });
}

export type SignatureUploadProps<Questionnaire extends QuestionnaireQuestions> =
  {
    checkboxOptions?: Record<string, { title: string; description?: string }>;
  } & QuestionnaireFormProps<Questionnaire, string>;

export const SignatureUpload = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  setValidForSubmission,
  checkboxOptions,
}: SignatureUploadProps<Questionnaire>) => {
  const { t } = useSafeTranslation();
  const { questionId } = useParams<{ questionId: string }>();
  const [signature, setSignature] = useState<string>('');
  const [checkboxValues, setCheckboxValues] = useState<string[]>([]);
  const [hasError, setHasError] = useState(false);

  const { uploadFile, uploadedFiles, loading, isSubmitValid, removeFile } =
    useUploadFiles({
      initialValue: [],
      isQuestionnaire: true,
      questionId,
      useV2: true,
    });

  const options = Object.entries(checkboxOptions || {}).reduce(
    (acc, [key, { title, description }]) => {
      acc[key] = { title: <Markdown>{title}</Markdown>, description };
      return acc;
    },
    {} as Record<string, { title: ReactNode; description?: string }>
  );

  const handleSubmit = () => {
    if (!signature) {
      setHasError(true);
      return;
    }

    setHasError(false);

    uploadFile([base64ToFile(signature, 'signature.png')]);
  };

  useEffect(() => {
    if (!uploadedFiles.length) {
      return;
    }

    const uploadedFile = uploadedFiles?.[0];

    if (uploadedFile?.error) {
      setHasError(true);
      removeFile(uploadedFile.id);
      return;
    }

    if (uploadedFile.token && isSubmitValid) {
      onSubmitValue(uploadedFile.token);
    }
  }, [
    isSubmitValid,
    onSubmitValue,
    removeFile,
    setValidForSubmission,
    uploadedFiles,
  ]);

  useEffect(() => {
    const allOptions = Object.keys(checkboxOptions || {});
    const areAllOptionsChecked = allOptions.every((option) =>
      checkboxValues.includes(option)
    );

    const isValid = signature && !loading && areAllOptionsChecked;

    setValidForSubmission(Boolean(isValid));
  }, [
    checkboxOptions,
    checkboxValues,
    setValidForSubmission,
    loading,
    signature,
  ]);

  return (
    <Header onSubmit={handleSubmit}>
      <>
        <Card classNames={{ wrapper: 'wmx10' }}>
          <div
            className={`${styles.content} ${
              loading ? styles.contentLoading : ''
            }`}
          >
            <SignaturePad onChange={setSignature} />

            {checkboxOptions && (
              <Checkbox
                bordered={false}
                wide
                classNames={{
                  container: 'mt24 w100',
                  label: styles.checkboxLabel,
                }}
                onChange={setCheckboxValues}
                options={options}
                value={checkboxValues}
              />
            )}

            {loading && (
              <div className={styles.loader}>
                <Spinner size="m" />
              </div>
            )}
          </div>
        </Card>

        {hasError && (
          <InformationBox
            className="mt24 wmx10"
            title={t(
              'signupQuestionnaire.signature.error.title',
              'Something went wrong'
            )}
            variant="error"
          >
            <Markdown>
              {t(
                'signupQuestionnaire.signature.error.description',
                'We encountered an issue while saving your signature, please try again.\n\nIf the issue persists, contact support.'
              )}
            </Markdown>
          </InformationBox>
        )}
      </>
    </Header>
  );
};
