import { capitalizeName } from '@getpopsure/public-utility';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { getPoliciesListAction } from 'features/policiesDashboard/utils/getPoliciesListAction';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import { PolicyDetailsInfo } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';
import { generatePath } from 'react-router';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import type { PolicySingleModal } from '../../components/modals';
import {
  CancelPolicyModal,
  DelinquencyBlockerCancelPolicyModal,
  DelinquencyBlockerCreateClaimModal,
} from '../../components/modals';
import { DATE_FORMAT } from '../../constants';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from '../../PolicySingle.models';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';
import { getLegalCoverageDetails } from './getLegalCoverageDetails';

export const getLegalConfig = (args: GetPolicyConfigArgs): PolicyConfig => {
  const [policy, t, featureFlags] = args;
  const {
    policyNumber,
    startDate,
    planId,
    partner,
    billingType,
    price,
    insuredPerson,
    addons,
  } = policy.attributes;

  const action = getPoliciesListAction(policy);
  const policyStatus =
    action && action !== 'DETAIL'
      ? 'ACTION_NEEDED'
      : getPolicyStatus(policy, true);
  const title = getTitleMapping(t)[policy.type];
  const capitalizedName = insuredPerson?.name
    ? capitalizeName(insuredPerson.name)
    : '';
  const subtitle = capitalizedName || '';

  const header: PolicyConfig['header'] = {
    title,
    subtitle,
    backgroundColor: bannerColorMapping.LEGAL,
    insuranceType: policy.type,
    policyStatus,
    startDate,
  };

  if (price) {
    header.price = {
      amount: parseFloat(price),
      billingType: billingType as BillingType,
    };
  }

  const showCancellationButton =
    policy.status !== 'CANCELED' && !policy.attributes?.inCancelation;

  const addonsRows: PolicyDetailsInfo[] = [];

  if (addons && addons.length > 0) {
    const addonStrings = addons.map((addon) =>
      addon === 'PROPERTY'
        ? t('myPolicies.policyDetails.legal.addons.home', 'Home')
        : t(
            'myPolicies.policyDetails.legal.addons.criminal',
            'Criminal protection'
          )
    );

    addonsRows.push({
      title: t('myPolicies.policyDetails.legal.addons.title', 'Add-ons'),
      value: addonStrings[0],
    });

    if (addonStrings[1]) {
      addonsRows.push({
        title: '',
        value: addonStrings[1],
      });
    }
  }

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: [
          {
            id: 'policyNumber',
            title: t('myPolicies.policyDetails.policyNumber', 'Policy number'),
            value: policyNumber,
          },
          {
            id: 'insuredPerson',
            title: t(
              'myPolicies.policyDetails.insuredPerson',
              'Insured person'
            ),
            value: insuredPerson && capitalizeName(insuredPerson.name),
          },
          {
            id: 'startDate',
            title: t('myPolicies.policyDetails.startDate', 'Start date'),
            value: startDate && dayjs(startDate).format(DATE_FORMAT),
          },
          {
            id: 'planId',
            title: t('myPolicies.policyDetails.legal.plan.title', 'Plan'),
            value:
              planId === 'ADVANCED'
                ? t('myPolicies.policyDetails.legal.plan.advanced', 'Advanced')
                : t('myPolicies.policyDetails.legal.plan.basic', 'Basic'),
          },
          ...addonsRows,
          {
            id: 'partner',
            title: t(
              'myPolicies.policyDetails.legal.includedSpouse',
              'Included spouse or partner'
            ),
            value: partner && capitalizeName(partner),
          },
        ],
        buttons: [
          { id: 'CONTACT_US', component: ContactUsButton },
          ...(showCancellationButton
            ? [{ id: 'CANCEL_POLICY', component: CancelPolicyButton }]
            : []),
        ],
      },
    },
    {
      id: 'POLICY_COVERAGE',
      props: {
        entries: getLegalCoverageDetails(t, policy),
      },
    },
    {
      id: 'POLICY_CLAIMS',
      props: {
        claims: policy.claims,
        route: featureFlags?.isLegalClaimBirdhouse
          ? generatePath(routes.claims.general.path, {
              verticalId: 'legal',
              policyId: policy.id,
            })
          : generatePath(routes.claims.legal.claimsAndConsultations.path, {
              policyId: policy.id,
            }),
        insuranceType: 'LEGAL',
        activeUntil:
          policy.status === 'ACTIVE'
            ? undefined
            : policy.attributes.activeUntil,
        policyStatus: policy.status,
        screen: {
          button: {
            dataCy: 'me-policies-detail-make-a-claim',
            title: t(
              'myPolicies.policyDetails.legal.claimsAndConsultationsButton.caption',
              'Claims and consultations'
            ),
          },
        },
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'CANCEL_POLICY',
      component: CancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CREATE_CLAIM',
      component: DelinquencyBlockerCreateClaimModal,
    },
  ];

  return { header, modals, sections };
};
