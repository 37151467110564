import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { ErrorWithAction } from 'components/ErrorWithAction';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import { ProcessingView as ActionProcessingView } from 'shared/pages/Processing/Processing.view';

import { useSubmitQuestionnaire } from './useSubmitQuestionnaire';

type ProcessingProps<Questionnaire extends QuestionnaireQuestions> = {
  textList?: string[];
  endpointUrl: string;
} & QuestionnaireFormProps<Questionnaire>;

export const SignupProcessing = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  textList,
  description,
  endpointUrl,
  questionnaireAnswers,
  featureName,
}: ProcessingProps<Questionnaire> & {
  description?: string;
}) => {
  const { t } = useSafeTranslation();

  const {
    mutate: submitQuestionnaire,
    status,
    error,
  } = useSubmitQuestionnaire(questionnaireAnswers, featureName, endpointUrl);
  const history = useHistory();

  const [processingFailed, setProcessingFailed] = useState(false);

  const TIMEOUT_TIME = 3_000;
  const elementIndex = 0;
  const fadeInAnimation = false;

  const list: string[] = textList
    ? [textList[0]]
    : [t('signup.general.processing.loadingText', 'Processing...')];

  useEffect(() => {
    submitQuestionnaire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (error) {
        setProcessingFailed(true);
        return;
      }

      if (status === 'success') {
        onSubmitValue(true, {}, { replaceState: true });
      }
    }, TIMEOUT_TIME);

    return () => clearTimeout(timeoutId);
  }, [status, error, onSubmitValue]);

  if (processingFailed) {
    return (
      <ErrorWithAction
        title={t(
          'signup.general.submissionError.title',
          'Something went wrong'
        )}
        description={t(
          'signup.general.submissionError.description',
          'Sorry, something didn’t work as it should. Starting over should address this issue.'
        )}
        cta={{
          title: t('signup.general.submissionError.cta', 'Go back'),
          onClick: () => history.goBack(),
        }}
      />
    );
  }

  return (
    <ActionProcessingView
      elementIndex={elementIndex}
      fadeInAnimation={fadeInAnimation}
      text={list}
      description={description}
    />
  );
};
