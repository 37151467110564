import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { ErrorWithAction } from 'components/ErrorWithAction';
import routes from 'constants/routes';
import { useEffect, useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import { ProcessingView as ActionProcessingView } from 'shared/pages/Processing/Processing.view';

import { useSubmitCommonClaim } from './useSubmitCommonClaim';

type ProcessingProps<Questionnaire extends QuestionnaireQuestions> = {
  textList?: string[];
  claimType: string;
  submitKeys: (keyof Questionnaire)[];
} & QuestionnaireFormProps<Questionnaire>;

export const ClaimProcessing = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  textList,
  description,
  questionnaireAnswers: questionnaire,
  configuration,
  claimType,
  submitKeys,
  featureName,
}: ProcessingProps<Questionnaire> & {
  description?: string;
  configuration: {
    policyId: string;
  };
}) => {
  const { t } = useSafeTranslation();

  const policyId = configuration?.policyId;

  const {
    mutate: submitClaim,
    status,
    error,
  } = useSubmitCommonClaim(claimType);
  const history = useHistory();

  const [processingFailed, setProcessingFailed] = useState(false);

  const refOnSubmit = useRef<(value: unknown) => void>(onSubmitValue);

  const TIMEOUT_TIME = 3_000;
  const elementIndex = 0;
  const fadeInAnimation = false;

  const list: string[] = textList
    ? [textList[0]]
    : [t('claims.general.processing.loadingText', 'Processing claim...')];

  useEffect(() => {
    const answers = Object.entries(questionnaire).reduce(
      (acc, [key, value]) => {
        if (!submitKeys.includes(key)) {
          return acc;
        }
        acc[key] = value;
        return acc;
      },
      {} as QuestionnaireQuestions
    );

    const submit = () => {
      submitClaim({ answers, policyId });
    };

    submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (status === 'success') refOnSubmit.current(true);
      if (error) setProcessingFailed(true);
    }, TIMEOUT_TIME);

    return () => clearTimeout(timeoutId);
  }, [status, error]);

  const handlePolicyError = () => {
    history.push(
      generatePath(routes.claims.general.path, {
        verticalId: featureName,
        policyId,
      })
    );
  };

  if (processingFailed) {
    return (
      <ErrorWithAction
        title={t(
          'claims.general.submissionError.title',
          'Something went wrong'
        )}
        description={t(
          'claims.general.submissionError.description',
          'Sorry, something didn’t work as it should. Starting over should address this issue.'
        )}
        cta={{
          title: t('claims.general.submissionError.cta', 'Start over'),
          onClick: handlePolicyError,
        }}
      />
    );
  }

  return (
    <ActionProcessingView
      elementIndex={elementIndex}
      fadeInAnimation={fadeInAnimation}
      text={list}
      description={description}
    />
  );
};
