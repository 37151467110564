import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import endpoint from 'shared/api';

export const useSubmitQuestionnaire = <
  Questionnaire extends QuestionnaireQuestions
>(
  answers: Questionnaire,
  vertical: string,
  endpointUrl: string
) => {
  const mutation = useMutation({
    mutationFn: () => endpoint.network.post(endpointUrl, answers),
    onError: (error) => {
      Sentry.captureException(error, {
        tags: {
          feature: 'SIGNUP',
          vertical,
        },
        extra: {
          description: `[${vertical}] Error happened while submitting policy`,
        },
      });
    },
  });

  return mutation;
};
