import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
  RoutesObject,
} from '@getpopsure/qnr-framework';
import { InsuranceTypes } from 'models/insurances/types';
import { isRegionValid } from 'routeLocales';
import { GenericCheckoutV2 } from 'SignupQuestionnaire/components/GenericCheckoutV2/GenericCheckout';
import {
  PolicyDetailsConfiguration,
  PriceBreakdownConfiguration,
} from 'SignupQuestionnaire/components/GenericCheckoutV2/utils';

export const GeneralGenericCheckoutV2 = <
  Questionnaire extends QuestionnaireQuestions
>(
  props: QuestionnaireFormProps<Questionnaire> & {
    type: InsuranceTypes;
    genericQuestionnaireKey: string;
    hasCheckoutDocuments?: boolean;
    changeStartDatePath?: string;
    redirectAddDependent?: keyof Questionnaire | boolean;
    policyDetailsConfiguration?: PolicyDetailsConfiguration;
    priceBreakdownConfiguration?: PriceBreakdownConfiguration;
    mainPolicyId?: string;
    productName: string;
    planQuestionId?: string;
    returnQuestionIdOnError?: string;
    routes: RoutesObject<Questionnaire>;
  }
) => {
  const { questionnaireAnswers, returnQuestionIdOnError = '', routes } = props;

  const currentRegion =
    typeof questionnaireAnswers.region === 'string' &&
    isRegionValid(questionnaireAnswers.region)
      ? questionnaireAnswers.region
      : 'de';

  const changeStartDatePath = returnQuestionIdOnError
    ? routes?.[returnQuestionIdOnError]?.path
    : '';

  return (
    <GenericCheckoutV2
      {...props}
      region={currentRegion}
      changeStartDatePath={changeStartDatePath}
    />
  );
};
