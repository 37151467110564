import { Region } from '@getpopsure/public-models';
import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import { ErrorWithAction } from 'components/ErrorWithAction';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import routes from 'constants/routes';
import { InsuranceTypes } from 'models/insurances/types';
import { Policy } from 'models/policies';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';

import { interpolateData } from '../functions';
import { useFetchQuestionnaire, useFetchSharedConfig } from '../hooks';
import { QuestionnaireJSON } from '../model';
import { GeneralPreviewGenericCheckout } from './components/GenericCheckout/GeneralPreviewGenericCheckout';
import { GeneralPreviewGenericCheckoutV2 } from './components/GenericCheckoutV2/GeneralPreviewGenericCheckout';
import { PreviewQuote } from './components/Quote/PreviewQuote';
import { RegionManager } from './components/RegionManager';
import { PreviewReview } from './components/Review/PreviewReview';
import { PreviewSignupProcessing } from './components/SignupProcessing/PreviewSignupProcessing';
import { SignupQuestionnaire } from './components/SignupQuestionnaire';
import { VariantPicker } from './components/VariantPicker';

const basePath = routes.birdhouse.preview.policy.path;
const questionnairePath = routes.birdhouse.preview.policy.questionnaire.path;
const noRegionPath = routes.birdhouse.preview.policy.noRegion.path;

export const PreviewSignupPage = () => {
  const { regionId, verticalId } = useParams<{
    regionId: Region;
    verticalId: string;
  }>();

  const dispatch = useDispatch();

  const [answers, setAnswers] = useState<QuestionnaireQuestions>({});

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const verticalIdWithoutVariant = verticalId?.split('-')?.[0] || '';
  const { sharedConfig, sharedConfigErrorMessage } = useFetchSharedConfig(
    verticalIdWithoutVariant
  );

  const { json, questionnaireErrorMessage } = useFetchQuestionnaire(
    verticalIdWithoutVariant
  );

  useEffect(() => {
    if (sharedConfigErrorMessage ?? questionnaireErrorMessage) {
      setErrorMessage(sharedConfigErrorMessage ?? questionnaireErrorMessage);
    }
  }, [sharedConfigErrorMessage, questionnaireErrorMessage]);

  if (json === undefined) {
    return <TimedLoadSpinner delayInMilliseconds={3_000} />;
  }

  if (errorMessage || !json) {
    return (
      <ErrorWithAction
        title="Questionnaire error"
        description={errorMessage ?? ''}
      />
    );
  }

  const mainPolicy: Policy = {
    id: '123',
    type: json.meta.insuranceType as InsuranceTypes,
    applicationId: '12345',
    providerId: 'ProviderId',
    providerName: 'AOK',
    status: 'ACTIVE',
    userUploads: [],
    attributes: {
      policyNumber: '1234567890',
      SVNR: '1234567890',
      insuredPerson: {
        name: { firstName: 'InsuredFirstName', lastName: 'InsuredLastName' },
      },
    },
    documents: [
      {
        title: 'Important document',
        downloadURL: 'https://cdn.getpopsure.com/document.pdf',
      },
      {
        title: 'My other document',
        downloadURL: 'https://cdn.getpopsure.com/document.pdf',
      },
    ],
    claims: [],
  };

  const variants = json.meta.variants || [];
  const variant = variants.find(
    ({ slug }) => verticalId === `${json.meta.id}-${slug}`
  );

  const interpolatedJSON = interpolateData<QuestionnaireJSON>(
    {
      ...json,
      meta: {
        prefilledAnswers: variant
          ? {
              variant: variant?.slug,
              variantData: variant,
            }
          : {},
        ...(json.meta || {}),
      },
    },
    {
      answers,
      constants: {
        ...sharedConfig,
        ...json.meta.constants,
      },
      mainPolicy: json.meta.isDependent ? mainPolicy : undefined,
    }
  );

  if (variants.length && !variant && regionId) {
    return <VariantPicker meta={json.meta} />;
  }

  return (
    <RegionManager
      noRegionPath={noRegionPath}
      questionnairePath={questionnairePath}
      questionnaireMeta={json.meta}
    >
      <ErrorBoundary
        fallbackRender={() => (
          <ErrorWithAction
            title="Questionnaire data error"
            description="The questionnaire answers do not match the questions. Some questions have changed"
            cta={{
              title: 'Reset data',
              onClick: () => {
                dispatch(
                  flushGenericQuestionnaire(
                    verticalId as keyof GenericQuestionnaireState
                  )
                );

                window.location.reload();
              },
            }}
          />
        )}
      >
        <SignupQuestionnaire
          basePath={basePath}
          questionnairePath={questionnairePath}
          data={interpolatedJSON}
          components={{
            REVIEW: PreviewReview,
            QUOTE: PreviewQuote,
            GENERIC_CHECKOUT: GeneralPreviewGenericCheckout,
            GENERIC_CHECKOUT_V2: GeneralPreviewGenericCheckoutV2,
            SIGNUP_PROCESSING: PreviewSignupProcessing,
          }}
          debug
          regionId={regionId}
          verticalId={verticalId}
          onQuestionnaireAnswersChanged={setAnswers}
        />
      </ErrorBoundary>
    </RegionManager>
  );
};
