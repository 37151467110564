import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { GeneralQuestion } from 'features/generalQuestionnaire/model';
import { InsuranceTypes } from 'models/insurances/types';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { useSafeTranslation } from 'shared/i18n';
import { Review } from 'SignupQuestionnaire/components/Review';
import {
  QuestionReviewValueType,
  ReviewProps,
} from 'SignupQuestionnaire/components/Review/Review';

import {
  AutoStartDateType,
  createPostQuote,
  createPostQuoteV2,
} from '../../actions';
import { getReviewValue } from './getReviewValues';

export const GeneralReview = <
  Questionnaire extends QuestionnaireQuestions = QuestionnaireQuestions
>(
  props: Omit<
    ReviewProps<Questionnaire>,
    'createPostQuote' | 'insuranceType' | 'verticalId' | 'requestType'
  > & {
    apiPath: string;
    meta: Record<string, unknown>;
    transform?: {
      personalInfo: string[];
      tariffInfo: string[];
    };
    configuration: {
      questions: GeneralQuestion;
    };
    isIntermediate?: boolean;
    autoStartDate?: AutoStartDateType;
    useUniqueEndpoint?: boolean;
  }
) => {
  const {
    featureName,
    reviewValues,
    questionnaireAnswers,
    apiPath,
    transform,
    meta,
    configuration,
    autoStartDate,
    useUniqueEndpoint,
  } = props;
  const { t } = useSafeTranslation();

  const createPost = () => {
    const uploadTokens = configuration.questions.reduce<string[]>(
      (tokens, { type, id }) => {
        if (
          ['SIGNATURE_UPLOAD', 'FILE'].includes(type) &&
          id &&
          questionnaireAnswers?.[id]
        ) {
          const answer = questionnaireAnswers[id];

          if (Array.isArray(answer)) {
            tokens.push(...answer.map(String));
          } else {
            tokens.push(String(answer));
          }
        }

        return tokens;
      },
      []
    );

    if (useUniqueEndpoint) {
      return createPostQuoteV2({
        insuranceType: featureName,
        t,
        questionnaireAnswers,
        transform,
        meta,
        autoStartDate,
        uploadTokens,
      });
    }

    return createPostQuote({
      insuranceType: featureName,
      t,
      questionnaireAnswers,
      transform,
      meta,
      apiPath,
      autoStartDate,
      uploadTokens,
    });
  };

  return (
    <Review
      requestType="REVIEW_SUBMIT_INFO"
      verticalId={featureName as keyof GenericQuestionnaireState}
      insuranceType={featureName as InsuranceTypes}
      {...props}
      createPostQuote={createPost}
      reviewValues={
        reviewValues
          .map((reviewEntry) => {
            return {
              ...reviewEntry,
              value: getReviewValue(
                configuration?.questions ?? [],
                reviewEntry.path ?? ''
              ),
            };
          })
          .filter(
            (reviewEntry) => reviewEntry.value !== null
          ) as QuestionReviewValueType<Questionnaire>[]
      }
    />
  );
};
