import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { Claim } from 'models/claims';
import endpoint from 'shared/api';

import { InsuranceTypes } from '../../../../../models/insurances/types';
import { triggerPushNotifications } from '../../../../../shared/pushNotifications';

const transformQnrAnswersForClaimSubmit = (answers: QuestionnaireQuestions) => {
  let transformedAnswers = { ...answers };

  let thirdParty = {};

  if (answers.documentUpload && Array.isArray(answers.documentUpload)) {
    delete transformedAnswers.documentUpload;

    transformedAnswers = {
      ...transformedAnswers,

      uploadedDocumentTokens: answers.documentUpload.map(
        ({ token }: { token: string }) => token
      ),
    };
  }

  if (answers.thirdPartyName) {
    delete transformedAnswers.thirdPartyName;

    thirdParty = { ...thirdParty, name: answers.thirdPartyName };
  }

  if (answers.thirdPartyEmail) {
    delete transformedAnswers.thirdPartyEmail;

    thirdParty = { ...thirdParty, email: answers.thirdPartyEmail };
  }

  if (answers.thirdPartyAddress) {
    delete transformedAnswers.thirdPartyAddress;

    thirdParty = { ...thirdParty, address: answers.thirdPartyAddress };
  }

  if (Object.keys(thirdParty).length > 0) {
    return { ...transformedAnswers, thirdParty };
  }

  return transformedAnswers;
};

const submitClaim = <Questionnaire extends QuestionnaireQuestions>(
  claimInfo: Questionnaire
) => {
  return endpoint.network.post<Claim>('/me/claims', claimInfo);
};

export const useSubmitCommonClaim = <
  Questionnaire extends QuestionnaireQuestions
>(
  vertical: string
) => {
  const onSubmitClaim = ({
    answers,
    policyId,
  }: {
    answers: Partial<Questionnaire>;
    policyId: string;
  }) => {
    const transformedAnswers = transformQnrAnswersForClaimSubmit(answers);

    const submittableClaimInfo = {
      ...transformedAnswers,
      userPolicyId: policyId,
      type: vertical,
    };

    return submitClaim(submittableClaimInfo);
  };

  const mutation = useMutation({
    mutationFn: onSubmitClaim,
    onError: (error, variables) => {
      const { policyId } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'CLAIMS',
          vertical,
        },
        extra: {
          policyId,
          description: `[${vertical}] Error happened while submitting claims`,
        },
      });
    },
    onSuccess: () => {
      triggerPushNotifications(vertical as InsuranceTypes);
    },
  });

  return mutation;
};
