import { Locale, TFunction } from '@getpopsure/i18n-react';
import { email as supportEmail } from '@getpopsure/private-constants';
import * as Sentry from '@sentry/react';
import { InsuranceTypes } from 'models/insurances/types';
import endpoint from 'shared/api';

import { CheckoutDocumentsActions } from '../checkoutDocuments.actions';
import {
  CheckoutDocument,
  PollCheckoutDocumentsDetailsResponse,
} from '../models';

const REQUIRED_DOCUMENTS_AMOUNT = 3;

export const areDocumentsValid = (documents: CheckoutDocument[]): boolean => {
  return (
    Array.isArray(documents) &&
    documents.length === REQUIRED_DOCUMENTS_AMOUNT &&
    documents.every((document) => document.status === 'READY')
  );
};

const generateDocuments = async (
  questionnaireId: string,
  quoteId: string,
  language?: Locale
) => {
  return endpoint.network.post('/signups/checkout_docs', {
    questionnaireId,
    quoteId,
    language,
  });
};

const generateDocumentsWithStrapi = async ({
  questionnaireId,
  quoteId,
  locale,
}: {
  questionnaireId: string;
  quoteId: string;
  locale?: Locale;
}) => {
  return endpoint.network.post('/signups/v2/checkout_docs', {
    questionnaireId,
    quoteId,
    locale,
  });
};

export const getDocuments = async (questionnaireId: string) => {
  return endpoint.network.get<CheckoutDocument[]>(
    `/signups/checkout_docs/${questionnaireId}`
  );
};

export const storeDocuments = (
  questionnaireId: string,
  documents: CheckoutDocument[]
): CheckoutDocumentsActions => ({
  type: 'STORE_CHECKOUT_DOCUMENTS',
  documents,
  questionnaireId,
});

/**
 * TODO: [KONG] Remove after all vertical docs are generated by Strapi
 */
const insuranceTypesForNewCheckoutDocGeneration: InsuranceTypes[] = [
  'LIABILITY',
  'BIKE_ES',
  'LIFE_EU',
  'DENTAL_ES',
  'HOME_FR',
];

export const generateCheckoutDocuments = async (
  questionnaireId: string,
  quoteId: string,
  verticalId: InsuranceTypes,
  t: TFunction,
  language?: Locale
) => {
  try {
    if (insuranceTypesForNewCheckoutDocGeneration.includes(verticalId)) {
      await generateDocumentsWithStrapi({
        questionnaireId,
        quoteId,
        locale: language,
      });
    } else {
      await generateDocuments(questionnaireId, quoteId, language);
    }
  } catch (error) {
    Sentry.captureException(error, {
      level: 'error',
      tags: {
        feature: 'Checkout documents',
        description: `${verticalId} signup: failed to generate policy documents in the review step`,
      },
      extra: {
        verticalId,
        questionnaireId,
        quoteId,
      },
    });

    // The following error will be stored in Redux using the request type each vertical uses in the Review component.
    // It will be displayed in the UI if the document generation fails.
    throw new Error(
      t(
        'components.review.documents.errorMessage',
        'An error occurred when generating your policy documents. Please try again or contact us at {{supportEmail}}.',
        { supportEmail: supportEmail.help }
      )
    );
  }
};

export const pollCheckoutDocuments = async (
  verticalId: InsuranceTypes,
  questionnaireId: string | undefined
): Promise<PollCheckoutDocumentsDetailsResponse> => {
  try {
    if (!questionnaireId) {
      throw new Error(
        `${verticalId}: Questionnaire ID is missing - failed to fetch checkout documents`
      );
    }

    const { data: documents } = await getDocuments(questionnaireId);

    const isAnyDocumentErrored = documents.some(
      (document) => document.status === 'FAILED'
    );

    if (areDocumentsValid(documents)) {
      return { status: 'COMPLETED', payload: documents };
    }

    if (isAnyDocumentErrored) {
      throw new Error(
        `${verticalId}: One or more checkout documents have a "Failed" status`
      );
    }

    return { status: 'FETCHING', payload: null };
  } catch (error) {
    Sentry.captureException(error, {
      level: 'error',
      tags: {
        feature: 'Checkout documents',
        description: `${verticalId} signup: failed to get policy documents`,
      },
      extra: {
        vertical: verticalId,
        questionnaireId,
      },
    });
    return { status: 'ERROR', payload: null };
  }
};
